import '../App.css';


function HOF() {

  return(

	  <div className="App-privacy">
		  <body className="App-privacy-body">
			  <h1> POBBLEBONK HALL OF FAME </h1><br/>


			  <h2>2022</h2><br/>
			  <h3>Top Comedians</h3><br/>
			  1. ?<br/>
			  2. ?<br/>
			  3. ?<br/><br/>

			  <h3>Top Fans</h3><br/>
			  1. ?<br/>
			  2. ?<br/>
			  3. ?<br/><br/>

			  <h3>Top Mics</h3><br/>
			  1. ?<br/>
			  2. ?<br/>
			  3. ?<br/><br/>

			  <h3>Top Cities</h3><br/>
			  1. ?<br/>
			  2. ?<br/>
			  3. ?<br/>
		  </body>




	  </div>



  )



}


export default HOF;
