import '../App2.css';



function Boardroom2() {




  return (
	  <html>


		  <body>


			  <b><div class="centertext">Inside the boardroom</div></b><br/><br/>

			  <div class="centertext">7/12/23</div>



			  <pre>

				  {`

Comedy Cash is kicked out of the running for the app name, but I think it's important to get across that $ is central to the app.  Sure you can get bragging rights, but we think its cool that a comic gets a few hundred dollars right before end of year holidays.  It's not going to change anyones life but it's like a gift that comes with a title and you don't have to do anything different other than hitting the check-in button to participate.  And obviously if the app grows the prizes grow too.  That's the model.

We'll be adding $300 to the Brooklyn leaderboard.  Brooklyn has the most users and the most check-ins and it just has...potential.  It's largely because of @alexmakesjokes and @maxkaczor.  Even just looking at the open mic map, it's a legitimate resource in that city.  I'm unsure whether I should combine Brooklyn with all of NYC, this will be tbd.  I still don't know whether what we have in Brooklyn is enough to get a sponsor into it, but I think it could be fun to try.  Next year as we have more features and more users I would expect it will be much easier.    

So the app doesn't hyperfocus on Brooklyn, we're adding a 4th prize of $200 and a 5th prize of $100 to the global leaderboard.

Can this all end?  Absolutely, if it doesn't grow and people don't check-in.  Maybe the idea of comics making some money is just too foreign.  I don't know.  lol.  Then this just goes into the graveyard of apps and websites that have tried to make something work in stand up comedy (I can name 4 off the top of my head).  The titles and cash can all be stripped away and frankly should under the right circumstances.  But no plan B's until 2025 at the earliest.  Too many ideas left, things to learn, and fun to be had trying to make this work.

So check-in if you want it to stay!           

`}


				  </pre>











		  </body>

	  </html>
  );
}



export default Boardroom2;
