import '../App.css';
import React from 'react';



const Contact = () => {

return (
    <div className="App-contact">
	    <body className="App-contact-body">


		    <h1>Contact</h1>
		    <h2>email: pobblebonklive@gmail.com</h2>

		    



	    </body>

    </div>



);



}

export default Contact;
